import React, { createContext, useState, useContext } from 'react'

export const RegisterContext = createContext({})

export const RegisterProvider = (props) => {
  ////// CONTROLE DE MODULO
  const [isRegister, setIsRegister] = useState(false)
  // Exibição  e  Edição  e  Inclusão e Aprovação
  const [modo, setModo] = useState('Inclusão')

  ////PARAMETROS PARA MODO EDIÇÃO
  const [valueData, setValueData] = useState('')
  const [time, setTime] = useState('')
  const [date, setDate] = useState('')
  const [idSchedule, setIdSchedule] = useState('')
  const [approvalViewBlock, setApprovalViewBlock] = useState(false)

  //////  CADASTRP DE CLIENTE
  const [register, setRegister] = useState({
    id_client: '',
    situation: 'ativo',
    name: '',
    email: '',
    phone: '',
    birthday: '',
    whatsapp: '',
    cpf: '',
    rg: '',
    how_meet: '',
    company_id: '',
    id_dep: '',
    genre: '',
    schooling: '',
    holder_income: '',
    working_department: '',
    marital_status: '',
    spouse_name: '',
    spouse_birthday: '',
    father_name: '',
    mother_name: '',
    card_type: 'pre',
    limit: '',
    invoice_date: '',
    payment_type: '',
    block: '',
    comments: '',
    password: '',
    plan_id: '',
    plan_type: ''
  })

  //////// AGENDAMENTO
  const [scheduleRegister, setScheduleRegister] = useState({
    request_form: '',
    status: '',
    data_atendimento: '',
    appointment_type: '',
    attachment_url: '',
    user_id: null,
    partner_id: null,
    partner_address_id: null,
    service_id: null,
    valor: null,
    comments: '',
    activity: '',
    cpf: '',
    card_number: '',
    phone: ''
  })

  ///////// TABS :
  //informações
  const [partnerRegister, setPartnerRegister] = useState({
    name: '',
    company_name: '',
    document: '',
    phone: '',
    email: '',
    responsible: '',
    discount_type_pos: '',
    subsidy_pos: '',
    discount_type_pre: '',
    subsidy_pre: '',
    situation: '',
    installment: 1
  })

  const [idPatner, setIdPatner] = useState(null)

  //dados bancarios
  const [infoBank, setInfoBank] = useState([])
  const [partnerBackRegister, setPartnerBackRegister] = useState({
    partner_id: null,
    bank_id: '',
    agency: '',
    account: null,
    owner_account: '',
    owner_document: '',
    pix_key_type: '',
    pix: '',
    pix_identification: ''
  })

  // Endereço

  // Procedimentos
  const [procedureRegister, setProcedureRegister] = useState({
    partner_id: null,
    status: '',
    name: '',
    valor: null,
    editable_value: 'false',
    description: ''
  })

  // Acesso usuarios
  const [accessUsers, setAccessUsers] = useState({
    partner_id: null,
    situation: '',
    name: '',
    email: '',
    password: null,
    criar_agendamento: 0,
    historico_lancamento: 0,
    extrato_financeiro: 0
  })
  // extrato financeiro

  const [dataBlock, setDataBlock] = useState([])

  // lançamento financeiro

  const [bodyFinancial, setBodyFinancial] = useState({
    data_lancamento: '',
    status: '',
    category: '',
    launch_in_type: 'partner',
    launch_in_id: null,
    unity_id: null,
    release_type: 'credit',
    valor: null,
    description: '',
    installments: 1
  })

  /////////////////////////////////////
  // atualizar dados usuario de acesso
  const [bodyUpdate, setBodyUpdate] = useState({
    id: '',
    name: '',
    status: '',
    email: '',
    criar_agendamento: 0,
    historico_lancamento: 0,
    extrato_financeiro: 0
  })

  const [accredited_companies, setAccredited_companies] = useState({
    id: '',
    name: '',
    company_name: '',
    document: '',
    email: '',
    phone: '',
    phone2: '',
    website: '',
    contact: '',
    responsible: '',
    situation: '',
    bank_id: '',
    agency: '',
    account: null,
    owner_account: '',
    owner_document: '',
    pix_key_type: '',
    pix: '',
    pix_identification: '',
    dados_bancarios: []
  })

  /////// MODAL - Emissão de Cartão ///////
  const [openModal, setOpenModal] = useState(false)

  /////// MODAL - Contrato de adesao ///////
  const [openModalContract, setOpenModalContract] = useState(false)

  /////// Encargos e Taxas (cartões pós-pago) /////
  const [fees, setFees] = useState({
    month_percentage: '',
    reprocessing: '',
    value_fine: '',
    type: 'day',
    id: ''
  })

  return (
    <RegisterContext.Provider
      value={{
        register,
        setRegister,
        isRegister,
        setIsRegister,
        scheduleRegister,
        setScheduleRegister,
        modo,
        setModo,
        valueData,
        setValueData,
        time,
        setTime,
        date,
        setDate,
        idSchedule,
        setIdSchedule,
        approvalViewBlock,
        setApprovalViewBlock,
        partnerRegister,
        setPartnerRegister,
        partnerBackRegister,
        setPartnerBackRegister,
        idPatner,
        setIdPatner,
        procedureRegister,
        setProcedureRegister,
        infoBank,
        setInfoBank,
        accessUsers,
        setAccessUsers,
        dataBlock,
        setDataBlock,
        bodyFinancial,
        setBodyFinancial,
        bodyUpdate,
        setBodyUpdate,
        accredited_companies,
        setAccredited_companies,
        openModal,
        setOpenModal,
        fees,
        setFees,
        openModalContract,
        setOpenModalContract
      }}
    >
      {props.children}
    </RegisterContext.Provider>
  )
}

export const useRegisterContext = () => useContext(RegisterContext)
